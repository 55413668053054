export const column1 = [
    "JavaScript",
    "Typescript",
    "HTML / CSS",
    "React",
    "Next.js",
    "Recoil",
];

export const column2 = [
    "Bootstrap",
    "ChakraUI",
    "Node.js",
    "Express",
    "KNEX",
    "PostgreSQL",
];
